/* eslint-disable */

import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useField } from '@unform/core';

import { Input, Icon, Container } from './styles';

const InputCheckbox = ({ name, onChange, inputValue, mode, label, id, ...props}) => {

    const inputRef = useRef(null);

    const [inputVal, setInputVal] = useState('');

    const {
        fieldName,
        error,
        registerField,
        clearError,
    } = useField(name);

    const handleInputChange = useCallback(e => {
        const value = e.target.checked;

        if( value ){
            clearError();
        }

        setInputVal(value);

        onChange && onChange(value);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path : 'checked',
            setValue(ref, value) {
                ref.setInputValue(value);
            },
            clearValue(ref) {
                ref.setInputValue('');
            }
        });
    }, [fieldName, registerField]);

    useEffect(() => {
        inputValue && inputValue(inputVal);
    }, [inputVal]);

    return(
        <Container>
            <Input
                id={id}
                name={fieldName}
                onChange={handleInputChange}
                ref={inputRef}
                {...props}
            />
            <Icon></Icon>
            <label htmlFor={id}>{label}</label>
            {error && (
                <div>{error}</div>
            )}
        </Container>
    );
}

export default InputCheckbox;
